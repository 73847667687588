.searchInputs {
  margin-top: -7px;
  display: flex;
  padding: 7px;
}

.search  {
  background-color: white ;
  border: 0;
  border-radius: 2px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  font-size: 18px;
  padding: 10px;
  height: 30px;
  width: 300px;
  margin-top: 30px;
  margin-bottom: 30px;
  z-index: 10;
}

.search  input {
  background-color: white;
  border: 0;
  border-radius: 1px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  font-size: 18px;
  padding: 10px;
  height: 30px;
  width: 300px;
  margin-top: -10px;
  margin-bottom: 10px;
  
}
.searchIcon {
  height: 20px;
  width: 30px;
  background-color: white;
  display: grid;
}

input:focus {
  outline: none;
}
.searchIcon svg {
  font-size: 20px;
}

.dataResult {
  margin-top: 0px;
  width: 300px;
  height: 250px;
  position: absolute;
  background-color: #1A374D ;
  font-size: 16px;
  z-index: 1000000000;


}

.dataResult::-webkit-scrollbar {
  display: none;
}

.dataResult .dataItem {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  color: white ;
  background-color: #1A374D ;
  border: 0;
  border-radius: 1px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  z-index: 20000000000;
}

.dataItem p {
  margin-left: 10px;
}
a {
  text-decoration: none;
}


#clearBtn {
  cursor: pointer;
}

.colorBusqueda{
  color: white !important;
  margin-top: 10px;
}