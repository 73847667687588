.TableConsultarTodo {
    margin: 0 auto;
    width: 98%;
    font-size: 13px;
  }
  
  /* CUERPO TABLA */
  .TableConsultarTodo td:nth-child(12),
  .TableConsultarTodo td:nth-child(13){
      text-align: center;
  }

  .cancel{
    cursor: pointer;  
    }
  
  /* RESPONSE TABLA */
  @media only screen and (max-width: 1920px) {
    .TableConsultarTodo  {
        width: 98%;
        font-size: 12.8px;

  
    }
    .cancel{
        cursor: pointer;
        width: 38%;
        height: 38%;
        
    }
    #custom-select, #custom-filter{
        font-size: 12.8px !important;
 
    }
  }

  @media only screen and (max-width: 1680px) {
    .TableConsultarTodo  {
        font-size: 10.6px;
        width: 98%;
  
    }
    #custom-select, #custom-filter{
        font-size: 10.6px !important;
 
    }
  }

  @media only screen and (max-width: 1600px) {
    .TableConsultarTodo  {
        font-size: 10px;
        width: 98%;
  
    }
    #custom-select, #custom-filter{
        font-size: 10 !important;
 
    }
  }



  @media only screen and (max-width: 1440px) {
    .TableConsultarTodo  {
        font-size: 8.6px;
        width: 98.5%;
  
    }
    .cancel{
        cursor: pointer;
        width: 48%;
        height: 48%;
        
        }
        #custom-select, #custom-filter{
            font-size: 8.5px;
        }
  }

  
  @media only screen and (max-width: 1368px) {
    .TableConsultarTodo  {
        font-size: 8.5px;
        width: 98.5%;
  
    }
    #custom-select, #custom-filter{
        font-size: 8.5px !important;
 
    }
    #custom-select{
        width: 68px;
    }


  }

  @media only screen and (max-width: 1280px) {
    .TableConsultarTodo  {
        font-size: 7.8px;
        width: 98%;
  
    }

    #custom-select, #custom-filter{
        font-size: 7.8 !important;
 
    }
    #custom-select{
        width: 62px;
    }

    .cancel{
        cursor: pointer;
        width: 48%;
        height: 48%;
        
    }

    .button {        
        
        font-size: 8px;
        padding: 2px;
        width: 80px;
        margin: 4px;
        }

    }


    @media only screen and (max-width: 1152px) {
        .TableConsultarTodo  {
            font-size: 7px;
            width: 94%;
            margin:  0 0;   
            padding-left: 10px;   
        }
    
        #custom-select, #custom-filter{
            font-size: 7 !important;
     
        }
        #custom-select{
            width: 58px;
        }
    
        .cancel{
            cursor: pointer;
            width: 48%;
            height: 48%;
            
        }
    
        .button {        
            
            font-size: 8px;
            padding: 2px;
            width: 80px;
            margin: 4px;
            }
    
        }
  

   


    @media only screen and (max-width: 1024px) {

        .TableConsultarTodo  {
            font-size: 7px;
            width: 83%;
            margin:  0 0;   
            padding-left: 10px;   
        }
    
        #custom-select, #custom-filter{
            font-size: 7 !important;
     
        }
        #custom-select{
            width: 58px;
        }
    
        .cancel{
            cursor: pointer;
            width: 48%;
            height: 48%;
            
        }
    
        .button {        
            
            font-size: 8px;
            padding: 2px;
            width: 80px;
            margin: 4px;
            }
    
        
        }
  
  @media only screen and (max-width: 830px) {
    .TableConsultarTodo  {
        font-size: 7px;
        max-width: 66%;
        margin:  0 0;   
        padding-left: 10px;   
    }

    #custom-select, #custom-filter{
        font-size: 7 !important;
 
    }
    #custom-select{
        width: 58px;
    }

    .cancel{
        cursor: pointer;
        width: 48%;
        height: 48%;
        
    }

    .button {        
        
        font-size: 8px;
        padding: 2px;
        width: 80px;
        margin: 4px;
        }

    
    }
 


  .div1c .fi03{
      width: 50%;
  }


  .dimencionboton{
      width: 10%;
  }


  .filtrosBusqueda{
      display: none;
  }


.div1c { float: left; margin-top: 36px; padding-top: 30px;}
.div2c { float: left;margin-top: 34px; padding-top: 30px; }
.div3c { float: left;margin-top: 36px; padding-top: 30px;  }
.div4c { float: left; margin-top: 36px; padding-top: 30px; padding-right: 40px;}
.div5c { float: left; margin-top: 34px; padding-top: 30px;}

.filtroRFC{
    width: 70%;
}

.filtroBP{
    width: 120%;
}


.expCSV{
    cursor: pointer;
}


