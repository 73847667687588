/* .main-filter {
    height: 100%;
    background-color: #eff0f2;
    color: #287993;
  }
  
  form, .title {
      padding-left: 100px;
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 15px;
  }
  
  .main-header__left>div,
  .main-header__right>div {
    display: flex;
  }
  
  .main-header__left>div>label,
  .main-header__right>div>label {
    width: 40%;
  }

  .filter-button1 {
    margin: 0;
    background: #38acd2;
    color: #FFFFFF;
    min-height: 35px;
    font-weight: bold;
    border: none;
    margin-top: 18px;
    width: 100%;
  }
  
  input, select{
    width: 100%;
    height: 30px;
    border: 1px solid #38acd2;
  }
  
  @media only screen and (max-width: 500px) {
   
    .main-header__right {
      grid-area: 2 / span 1;
      grid-gap: 0;
    }
  }





  * {
				margin:0px;
				padding:0px;
			}
			
			#header {
				margin:auto;
				width:100%;
				font-family:Arial, Helvetica, sans-serif;
			} */
      ul, ol {
        list-style:none;
      }
          
    .nav > li {
      float:left;
    }
          
    .nav li a {
      background: #1A374D;
      color:#fff;
      text-decoration:none;
      padding:10px 12px;
        text-align: left;
      display:block;
      z-index: 12;
      }
          
    .nav li a:hover {
      background-color:#434343;
      }
          
    .nav li ul {
      display:none;
      position:absolute;
      min-width:140px;
    
    }
          
          .nav li:hover > ul {
            display:block;
          }
          
          .nav li ul li {
            position:relative;
            z-index: 20000000000;
    
    
          }
          
          .nav li ul li ul {
            right:-280px;
            top:0px;
            z-index: 20000000000;
    
          }
    
          /*socio de negocios termina*/
    
    
          
          .nav2 > li {
            float:left;
          }
          
          .nav2 li a {
            background: #1A374D;
            color:#fff;
            text-decoration:none;
            padding:10px 12px;
            display:block;
          }
          
          .nav2 li a:hover {
            background-color:#434343;
          }
          
          .nav2 li ul {
            display:none;
            position:absolute;
            min-width:340px;
          }
          
          .nav2 li:hover > ul {
            display:block;
          }
          
          .nav2 li ul li {
            position:relative;
            z-index: 20000000000;
    
          }
          
          .nav2 li ul li ul {
            right:-140px;
            top:0px;
    
          }

.containerfull {
  background: #1a374d;
}

.container2 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas: "logo logo menu buscador buscador . . . . usuario";
}

.menu {
  grid-area: menu;
  padding-top: 37px;
}

.buscador {
  grid-area: buscador;
  padding-top: 15px;
}

.usuario {
  grid-area: usuario;
  padding-top: 27px;
}
.encabezadoA{
  color: #fff !important;
} 

.logo {
  grid-area: logo;
}
.logo-img {
	padding-top: 20PX;
  width: 100px;
  height: 82.5px;
}
